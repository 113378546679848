import React, { useState } from "react";
import Footer from "../layout/Footer";
import OwlCarousel from "react-owl-carousel";
import quote from "../../src/assets/img/straight-quotes.png";
import aboutus from "../../src/assets/img/aboutus.png";
import testmonialbg from "../../src/assets/img/h2-6.webp";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import management from "../../src/assets/img/management.png";
import planning from "../../src/assets/img/planning.png";
import safety from "../../src/assets/img/safety.png";
import lightbulb from "../../src/assets/img/lightbulb.png";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
const About = () => {
  const partnerlogo = {
    loop: true,
    margin: 30,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 4, // Show 1 item for screens 0px and up
      },
      600: {
        items: 5, // Show 2 items for screens 600px and up
      },
      1000: {
        items: 6, // Show 4 items for screens 1000px and up
      },
    },
  };

  const testimonial = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  const aboutslider = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  const testmonialStyle = {
    backgroundImage: `url(${testmonialbg})`,
  };
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };
  const aboutbg = {
    backgroundImage: `url(${aboutus})`,
  };
  const [activeTab, setActiveTab] = useState("Buildings");

  return (
    <>
      <Header></Header>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>About Us</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>About Us</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="about-innerpage">
            <div className="row mx-0">
              <div className="col-md-5 px-md-0">
              <div className="bg-about-inner h-100" style={aboutbg}>
              </div>
              </div>
              <div className="col-md-7 px-md-0">
                <div className=" bg-about-content-outer">
                <div className="section-title bg-about-content">
                  <span>About us</span>
                  <h2>
                  Creative Problem Solvers Focused On
                  Simplifying Safety
                  </h2>
                  <p className="mb-xl-4">
                  Welcome to cue solution. we have been a trusted Creative Problem Solvers 
                  Focused On Simplifying Safety provider to the cunstruction industry. 
                  </p>
                </div>
                </div>
              </div>
            </div>
        </section>
        <section className="whatweoffer aboutus-whtaweoffer">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-title mb-md-5 mb-4">
                  <span>What We offer</span>
                  <h2>
                    Bespoke safety solutions, with a focus on end user
                    experience
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={safety} alt="" />
                  <h3>Health &amp; Safety</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={planning} alt="" />
                  <h3>Technology Solutions</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={management} alt="" />
                  <h3>Management</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={lightbulb} alt="" />
                  <h3>planning</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
       <Partners></Partners>
        <section className="testimonial-section" style={testmonialStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div className="section-title">
                  <span>testimonials</span>
                  <img src={quote} alt="" />
                  <h2>What Your Client Say?</h2>
                </div>
                <OwlCarousel
                  className="owl-theme pxl-testimonial-carousel"
                  {...testimonial}
                >
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                        The entire team at Sutcliffe Construction filled me with
                        confidence and the project ran smoothly from start to
                        finish. The site team were proficient and extremely
                        knowledgeable and I am 101% happy with the end result. I
                        would highly recommend Sutcliffe Construction to anyone
                        in the market for a steel-framed building. tes2 Stuart
                        Clark Managing Director, Bingley LTD view more
                        testimonials To say that this 3 month modernization was
                        a Herculean task would be an understatement. I remain
                        convinced at this time, 2 weeks prior to occupancy that
                        no other construction firm in the Bay Area could have
                        handled such a task and pulled it off successfully.
                        Alten collaborated with the district and design team on
                        cost savings including. lead5 Mr. R. Ramanujam Director
                        Operations and Sustainability view more testimonials
                        Throughout the whole project, Sutcliffe Construction
                        provided a professional and reliable service every step
                        of the way. The team delivered the project end-to-end,
                        demonstrating a determination to deliver our new
                        warehouse and office space to the highest standard.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/lead5-90x90.webp"
                              alt="lead5"
                              title="lead5"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3>Mr. R. Ramanujam</h3>
                            <p>Director Operations and Sustainability</p>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                        Throughout the whole project, Sutcliffe Construction
                        provided a professional and reliable service every step
                        of the way. The team delivered the project end-to-end,
                        demonstrating a determination to deliver our new
                        warehouse and office space to the highest standard.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/tes3-90x90.webp"
                              alt="tes3"
                              title="tes3"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3 className="pxl-item--title">
                              Andrew J Cawthray
                            </h3>
                            <div className="pxl-item--position">
                              Chairman & Chief Executive, Cawingredients Ltd
                            </div>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                        The entire team at Sutcliffe Construction filled me with
                        confidence and the project ran smoothly from start to
                        finish. The site team were proficient and extremely
                        knowledgeable and I am 101% happy with the end result. I
                        would highly recommend Sutcliffe Construction to anyone
                        in the market for a steel-framed building.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/tes2-90x90.webp"
                              alt="tes2"
                              title="tes2"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3 className="pxl-item--title">Stuart Clark</h3>
                            <div className="pxl-item--position">
                              Managing Director, Bingley LTD
                            </div>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
};

export default About;
