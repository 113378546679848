import React, { useState } from "react";
import Footer from "../layout/Footer";
import product1 from "../../src/assets/img/product1.png";
import product2 from "../../src/assets/img/product2.png";
import product3 from "../../src/assets/img/product3.png";
import Header from "../layout/Header";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const products = [
  {
    id: 1,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 2, title: "Lifting Point", imgSrc: product2 },
  { id: 3, title: "Lifting Point", imgSrc: product3 },
  {
    id: 4,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 5, title: "Lifting Point", imgSrc: product2 },
  { id: 6, title: "Lifting Point", imgSrc: product3 },
  {
    id: 7,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 8, title: "Lifting Point", imgSrc: product2 },
  { id: 9, title: "Lifting Point", imgSrc: product3 },
  {
    id: 10,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 11, title: "Lifting Point", imgSrc: product2 },
  { id: 12, title: "Lifting Point", imgSrc: product3 },
];

const ProductDetails = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Prodcutslider = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };

  const relatedpro = {
    loop: true,
    margin: 30,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <>
      <Header />
      <div className="margindiv-header">
        <section className="product-detailsinner">
          <div className="container">
            <div className="row">
              <div className="col-md-5 mb-md-0 mb-4">
                <div className="slider-box-product">
                  <OwlCarousel className="owl-theme" {...Prodcutslider}>
                    <div className="item">
                      <img src={product1} alt="Product 1" />
                    </div>
                    <div className="item">
                      <img src={product2} alt="Product 2" />
                    </div>
                    <div className="item">
                      <img src={product3} alt="Product 3" />
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-md-7 mb-md-0 mb-4 ps-md-4">
                <div className="product-details-content">
                  <h2>Lifting Point 8-231 Metric Thread / DIGITAL</h2>
                  <div className="description">Description</div>
                  <ul>
                    <li>Pivots through 180°.</li>
                    <li>
                      Manufactured from forged alloy steel, quenched and
                      tempered.
                    </li>
                    <li>
                      Manufactured and tested in accordance with EN1677-1.
                    </li>
                    <li>Load rated parts are 100% magnaflux crack detected.</li>
                    <li>
                      Individual forged parts are traceable to Test
                      Certification.
                    </li>
                    <li>Proof tested to 2.5 times the WLL.</li>
                    <li>Fatigue rated to 1.5 times the WLL.</li>
                    <li>
                      All YOKE Lifting points meet or exceed all the
                      requirements of ASME B30.26.
                    </li>
                    <li>
                      WLL forged onto each product for quick and easy
                      identification.
                    </li>
                    <li>Lugs designed to assist the welding process.</li>
                    <li>
                      A protected spring keeps the load ring in a required
                      position.
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={handleShow}
                    className="btn mt-4"
                  >
                    Product inquiry <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="related-products">
          <div className="container">
            <div className="section-title mb-md-5 mb-4">
              <span>Find More Essentials That Match</span>
              <h2>Related Products</h2>
            </div>
            <OwlCarousel className="row" {...relatedpro}>
              {products.map((product) => (
                <div className="item" key={product.id}>
                  <div className="product-box">
                    <Link to="/product-details">
                      <img src={product.imgSrc} alt={product.title} />
                      <div className="title-product">
                        <h2>{product.title}</h2>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section>
      </div>
      <Footer />
      {show && (
        <div className="modal show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content conatctuseform p-4 pt-3">
              <div className="modal-header p-0 border-0 mb-3">
                <h5 className="modal-title">Product Inquiry</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body px-0 pb-0">
                  <form class="needs-validation" novalidate>
                    <div class="row g-3 " novalidate>
                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="text"
                          class="form-control"
                          id="your-name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="text"
                          class="form-control"
                          id="your-name"
                          placeholder="Last Name"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="tel"
                          class="form-control"
                          id="your-phone"
                          placeholder="Phone Number"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="email"
                          class="form-control"
                          id="your-email"
                          placeholder="Email Address"
                          required
                        />
                      </div>
                      <div class="col-12 mb-4 mt-0">
                        <textarea
                          class="form-control"
                          id="your-message"
                          rows="4"
                          placeholder="Write Message..."
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="accept-terms"
                        required
                      />
                      <label class="form-check-label" for="accept-terms">
                        Accept terms and conditions from Graviton.
                      </label>
                      <div class="invalid-feedback">
                        You must agree before submitting.
                      </div>
                    </div>
                  <div className="text-center">
                  <button class="btn w-auto mt-3" type="submit">
                      Submit <i class="fa fa-arrow-right"></i>
                    </button>
                  </div>
                  </form>
              </div>
              
            </div>
          </div>
          {/* Overlay to close the modal when clicked */}
          <div className="modal-backdrop fade show" onClick={handleClose}></div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
