import React from "react";
import Footer from "../layout/Footer";
import OwlCarousel from "react-owl-carousel";
import partner1 from "../../src/assets/img/w8.webp";
import partner2 from "../../src/assets/img/ww-8.webp";
import partner3 from "../../src/assets/img/ww-9.webp";
import partner4 from "../../src/assets/img/ww-10.webp";
import partner5 from "../../src/assets/img/ww-11.webp";
import partner6 from "../../src/assets/img/ww-12.webp";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
const About = () => {
  const partnerlogo = {
    loop: true,
    margin: 30,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 4, // Show 1 item for screens 0px and up
      },
      600: {
        items: 5, // Show 2 items for screens 600px and up
      },
      1000: {
        items: 6, // Show 4 items for screens 1000px and up
      },
    },
  };

  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };

  return (
    <>
      <Header></Header>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Contact Us</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i class="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Contact Us</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="contact-quesolution">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 pe-md-4 mb-md-0 mb-4">
                <div className="section-title">
                  <span>let's work together</span>
                  <h2>Get In Touch With Us</h2>
                  <p className="mb-xl-4">
                    If you need to repair or replace your Plumbing system, call
                    today and talk to one of our Plumbing specialists.
                  </p>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="suppro-addres">
                        <p>Call Support</p>
                        <h3>
                          <a href="tel:+9112345678910">+91 12345678910</a>
                        </h3>
                      </div>
                    </div>
                    {/* {/ <div className="col-md-6 mb-3">
                      <div className="suppro-addres">
                        <h4>Call Support</h4>
                        <p>
                        37 San Juan Lane Graaf Florisstraat 22A, 3021 CH
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="suppro-addres">
                        <h4>Mail</h4>
                        <p>
                          <a href="tel:+9112345678910"> quesolution@gmail.com</a>
                        </p>
                      </div>
                    </div> /} */}
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div class="conatctuseform" novalidate>
                  <form class=" needs-validation" novalidate>
                    <div class="row " novalidate>
                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="text"
                          class="form-control"
                          id="your-name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="text"
                          class="form-control"
                          id="your-name"
                          placeholder="Last Name"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="tel"
                          class="form-control"
                          id="your-phone"
                          placeholder="Phone Number"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-4 mt-0">
                        <input
                          type="email"
                          class="form-control"
                          id="your-email"
                          placeholder="Email Address"
                          required
                        />
                      </div>
                      <div class="col-12 mb-4 mt-0">
                        <textarea
                          class="form-control"
                          id="your-message"
                          rows="4"
                          placeholder="Write Message..."
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-md-flex align-item-center justify-content-between">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="accept-terms"
                          required
                        />
                        <label class="form-check-label" for="accept-terms">
                          Accept terms and conditions from Graviton.
                        </label>
                        <div class="invalid-feedback">
                          You must agree before submitting.
                        </div>
                      </div>
                      <button class="btn" type="submit">
                        Submit <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Partners></Partners>
      </div>
      <Footer></Footer>
    </>
  );
};

export default About;
