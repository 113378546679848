import React, { useState } from "react";
import Footer from "../layout/Footer";
import OwlCarousel from "react-owl-carousel";
import quote from "../../src/assets/img/straight-quotes.png";
import aboutimg from "../../src/assets/img/h2-4.webp";
import testmonialbg from "../../src/assets/img/h2-6.webp";
import herobg from "../../src/assets/img/herobg.jpg";
import people from "../../src/assets/img/people.png";
import quality from "../../src/assets/img/quaity.png";
import innovation from "../../src/assets/img/innovation.png";
import management from "../../src/assets/img/management.png";
import planning from "../../src/assets/img/planning.png";
import safety from "../../src/assets/img/safety.png";
import lightbulb from "../../src/assets/img/lightbulb.png";
import img1 from "../../src/assets/img/img1.webp";
import img3 from "../../src/assets/img/img3.webp";
import img4 from "../../src/assets/img/img4.webp";
import shapeimg from "../../src/assets/img/G2.png";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
const Home = () => {
   const heroslider = {
    loop: true,
    margin: 0,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1, // Show 1 item for screens 0px and up
      },
      600: {
        items: 1, // Show 2 items for screens 600px and up
      },
      1000: {
        items: 1, // Show 4 items for screens 1000px and up
      },
    },
  };

  const testimonial = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  const aboutslider = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  const testmonialStyle = {
    backgroundImage: `url(${testmonialbg})`,
  };
  const herobgStyle = {
    backgroundImage: `url(${herobg})`,
  };
  const [activeTab, setActiveTab] = useState("Buildings");

  const tabsContent = {
    Buildings: {
      title: "Expertise in Health and Safety",
      description: `With extensive experience in health and safety in mining and construction, we provide tailored solutions for unique industry challenges and requirements.`,
      image: img1, // Use your own image URL
    },
    "Expertise in Health and Safety": {
      title: "Heavy Industrial Solutions, Tailored to Your Needs",
      description: `We offer heavy industrial construction services that are tailored to the specific needs 
      of our clients, ensuring safety, efficiency, and quality in every project.`,
      image: img3, // Use your own image URL
    },
    " Heavy Industrial": {
      title: "Innovative Special Projects, Breaking New Ground",
      description: `Our special projects team is dedicated to pushing the boundaries of what’s possible, 
      delivering innovative solutions for unique construction challenges.`,
      image: img4, // Use your own image URL
    },
  };

  const { title, description, image } = tabsContent[activeTab];
  return (
    <>
      <Header></Header>
      <div className="margindiv-header">
        <section className="hero-sec">
          <OwlCarousel className="owl-theme" {...heroslider} >
            <div className="item" style={herobgStyle}>
              <div className="container" data-aos="fade-up">
                <div className="welcomesolution">Welcome to Cue Solutions</div>
                <h1>
                  Innovative <span>Safety Solutions </span>
                </h1>
                <ul>
                  <li>
                    <img src={people} alt="" /> People
                  </li>
                  <li>
                    <img src={quality} alt="" />
                    Quality
                  </li>
                  <li>
                    <img src={innovation} alt="" /> Innovation
                  </li>
                </ul>
                <a href="" className="btn">
                  
                  Our Services
                  <i aria-hidden="true" className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </OwlCarousel>
        </section>
        <section className="about-quesolution">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 pe-md-4 mb-md-0 mb-4">
                <img
                data-aos="fade-up"
                  src={aboutimg}
                  alt="Graviton Since 1996"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7">
                <div className="section-title" data-aos="fade-left">
                  <span>Cue Solutions​</span>
                  <h2>
                  Creative Problem Solvers
                  Focused On Simplifying Safety
                  </h2>
                  <p className="mb-xl-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <div className="orl-casosel-inner">
                    <OwlCarousel className="owl-theme" {...aboutslider}>
                      <div className="about-owlslider">
                        <p>
                        We are proud to have built a reputation as a leader in the construction of high-quality facilities. Clients, industry professionals and financial institutions view us as a premier choice for design and general construction services.
                        </p>
                        <h3>Our Skill</h3>
                      </div>

                      <div className="about-owlslider">
                        <p>
                        We are proud to have built a reputation as a leader in the construction of high-quality facilities. Clients, industry professionals and financial institutions view us as a premier choice for design and general construction services.
                        </p>
                        <h3>Our Skill</h3>
                      </div>
                    </OwlCarousel>
                  </div>
                  <a href="" className="btn mt-md-5 mt-4">
                    Our Services
                    <i aria-hidden="true" className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatweoffer">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-title mb-md-5 mb-4" data-aos="fade-right">
                  <span>What We offer</span>
                  <h2>
                    Bespoke safety solutions, with a focus on end user
                    experience
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-md-0 mb-4 mb-sm-6">
                <div className="whtwe-box"  data-aos="fade-up">
                  <img src={safety} alt="" />
                  <h3>Health &amp; Safety</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-md-0 mb-4 mb-sm-6">
                <div className="whtwe-box"  data-aos="fade-up">
                  <img src={planning} alt="" />
                  <h3>Technology Solutions</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-md-0 mb-4 mb-sm-6">
                <div className="whtwe-box"  data-aos="fade-up">
                  <img src={management} alt="" />
                  <h3>Management</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-md-0 mb-4 mb-sm-6">
                <div className="whtwe-box"  data-aos="fade-up">
                  <img src={lightbulb} alt="" />
                  <h3>Planning</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <img
            src={shapeimg}
            className="rightimg-about"
            alt=""
          />

          <img
            src={shapeimg}
            alt=""
            className="lefttimg-about"
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-5  col-lg-6  mb-lg-5 mb-4 section-title"  data-aos="fade-right">
                <span>About Us</span>
                <h2>Driven towards continual
                improvement</h2>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-7  pe-lg-5 mb-lg-0 mb-4">
              <img src={image} alt={activeTab} className="img-fluid" />
            </div>
            <div className="col-lg-5">
              <div className="tabs mb-lg-5 mb-4">
                {Object.keys(tabsContent).map((tab) => (
                  <button
                    key={tab}
                    className={`tab-button ${
                      activeTab === tab ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className="section-title innerabout-tab">
                <h2>{title}</h2>
                <p>{description}</p>

                <a href="#" className="btn">
                  Learn More <i className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
       <Partners></Partners>
        
        <section className="testimonial-section" style={testmonialStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="section-title">
                  <span>testimonials</span>
                  <img src={quote} alt="" />
                  <h2>What Your Client Say?</h2>
                </div>
                <OwlCarousel
                  className="owl-theme pxl-testimonial-carousel"
                  {...testimonial}
                >
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                       
                        Throughout the whole project, Sutcliffe Construction
                        provided a professional and reliable service every step
                        of the way. The team delivered the project end-to-end,
                        demonstrating a determination to deliver our new
                        warehouse and office space to the highest standard.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/lead5-90x90.webp"
                              alt="lead5"
                              title="lead5"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3>Mr. R. Ramanujam</h3>
                            <p>Director Operations and Sustainability</p>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                        Throughout the whole project, Sutcliffe Construction
                        provided a professional and reliable service every step
                        of the way. The team delivered the project end-to-end,
                        demonstrating a determination to deliver our new
                        warehouse and office space to the highest standard.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/tes3-90x90.webp"
                              alt="tes3"
                              title="tes3"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3 className="pxl-item--title">
                              Andrew J Cawthray
                            </h3>
                            <div className="pxl-item--position">
                              Chairman & Chief Executive, Cawingredients Ltd
                            </div>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pxl-item--inner">
                      <div className="pxl-item--desc">
                        The entire team at Sutcliffe Construction filled me with
                        confidence and the project ran smoothly from start to
                        finish. The site team were proficient and extremely
                        knowledgeable and I am 101% happy with the end result. I
                        would highly recommend Sutcliffe Construction to anyone
                        in the market for a steel-framed building.
                      </div>
                      <div className="content-bottom">
                        <div className="pxl-item--holder pxl-flex-middle">
                          <div className="pxl-item--avatar">
                            <img
                              src="https://demo.bravisthemes.com/graviton/wp-content/uploads/2024/01/tes2-90x90.webp"
                              alt="tes2"
                              title="tes2"
                            />
                          </div>
                          <div className="pxl-item--meta">
                            <h3 className="pxl-item--title">Stuart Clark</h3>
                            <div className="pxl-item--position">
                              Managing Director, Bingley LTD
                            </div>
                          </div>
                        </div>
                        <div className="btn-more">
                          <a href="https://demo.bravisthemes.com/graviton/testimonials/">
                            View more testimonials
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Home;
