import React from "react";
import { Link } from "react-router-dom";
import logo from "../../src/assets/img/logo.png";
import footerbg from "../../src/assets/img/bg-ft-1.webp";

const footerStyle = {
  backgroundImage: `url(${footerbg})`,
};

function Footer() {
  return (
    <footer className="footer" style={footerStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            {" "}
            <div className="ready-to-work">
              <h3>Ready To Work Together?</h3>
              <p>
                Whether you have a project in mind and you’re looking for a
                reliable construction partner or you’re looking to take the next
                step in your career, we want to hear from you!
              </p>
              <a href="" className="btn pxl-icon-active">
                <span className="pxl--btn-text"> Get A Quote </span>
                <i aria-hidden="true" className="fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="menu-footer">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/qtag">Qtag</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="row my-md-5 my-4">
          <div className="col-md-4 mb-md-0 mb-4">
            <div className="logofooters">
              <a href="/">
                <img src={logo} alt="Logo" />
              </a>
              <div className="social-media-links">
                <Link to="https://facebook.com">
                  <i aria-hidden="true" className="fab fa-facebook-f"></i>
                </Link>
                <Link to="https://twitter.com">
                  <i aria-hidden="true" className="fab fa-twitter"></i>
                </Link>
                <Link to="https://skype.com">
                  <i aria-hidden="true" className="fab fa-skype"></i>
                </Link>
                <Link to="mailto:quesolution@gmail.com">
                  <i aria-hidden="true" className="fas fa-envelope"></i>
                </Link>
                <Link to="https://instagram.com">
                  <i aria-hidden="true" className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-md-0 mb-4">
            <div className="footer-widgets">
              <h3>Address Company</h3>
              <div className="pxl-icon-box">
                <div className="pxl-item--meta">
                  <a href="https://www.google.com/maps" target="_blank">
                    <i aria-hidden="true" className="fas fa-map-marker-alt"></i>
                    <span>
                      37 San Juan Lane
                      <br />
                      Graaf Florisstraat 22A, 3021 CH
                    </span>
                  </a>
                </div>
                <div className="pxl-item--meta">
                  <a href="tel:+18091206705">
                    <i aria-hidden="true" className="fa fa-phone"></i>
                    <span>Call Us: +1.809.120.6705</span>
                  </a>
                </div>
                <div className="pxl-item--meta">
                  <a href="mailto:quesolution@gmail.com">
                    <i aria-hidden="true" className="fa fa-envelope"></i>
                    <span>Mail: quesolution@gmail.com</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-md-0 mb-4">
            <div className="footer-widgets">
              <h3>Subscribe Newsletter</h3>
              <p>
                Subscribe to receive the latest news from us; we make sure not
                to spam your inbox.
              </p>
              <form>
                <div className="input-filled">
                  <input
                    className="form-control"
                    placeholder="*Email address"
                    type="email"
                    name="your-email"
                  />
                  <button type="submit" className="">
                    <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="pxl-el-divider"></div>
        <div className="copyright">
          <div className="row">
            <div className="col-md-6">
              <p>
                Copyright © 2024 <Link to="/">Cue Solutions</Link>. All Rights
                Reserved.
              </p>
            </div>
            <div className="col-md-6">
              <div className="privacy-links">
                <a href="#">Privacy Policy</a> <a href="#">Terms of Use</a>
                <a href="#">Site Map</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
