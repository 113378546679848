import { useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import Home from "./page/Home";
import "./Responsive.css";
import About from "./page/About";
import Contact from "./page/Contact";
import Product from "./page/Product";
import ProductDetails from "./page/ProductDetails";
import StaticPages from "./page/StaticPage";
import Faq from "./page/Faq";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
    });
    AOS.refresh(); // Refresh AOS if needed
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/static-page" element={<StaticPages />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
