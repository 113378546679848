import React, { useState } from "react";
import { Link } from "react-router-dom";
import product1 from "../../src/assets/img/product1.png";
import product2 from "../../src/assets/img/product2.png";
import product3 from "../../src/assets/img/product3.png";

// Sample Product Data
const products = [
  {
    id: 1,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 2, title: "Lifting Point", imgSrc: product2 },
  { id: 3, title: "Lifting Point", imgSrc: product3 },
  {
    id: 4,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 5, title: "Lifting Point", imgSrc: product2 },
  { id: 6, title: "Lifting Point", imgSrc: product3 },
  {
    id: 7,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 8, title: "Lifting Point", imgSrc: product2 },
  { id: 9, title: "Lifting Point", imgSrc: product3 },
  {
    id: 10,
    title: "Lifting Point 8-231 Metric Thread / DIGITAL",
    imgSrc: product1,
  },
  { id: 11, title: "Lifting Point", imgSrc: product2 },
  { id: 12, title: "Lifting Point", imgSrc: product3 },
];

const Productlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;

  // Calculate the products to show on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className="row">
        {currentProducts.map((product) => (
          <div className="col-lg-4 col-md-4 col-sm-6 mb-4" key={product.id}>
            <div className="product-box">
              <Link to="/product-details">
                <img variant="top" src={product.imgSrc} alt={product.title} />
                <div className="title-product">
                  <h2>{product.title}</h2>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination with Previous and Next Arrows */}
      <nav>
        <ul className="pagination justify-content-center mt-md-5 mt-4">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={handlePreviousPage}
              aria-label="Previous"
            >
              <i className="fa fa-chevron-left"></i>
            </button>
          </li>
          {[...Array(totalPages)].map((_, index) => (
            <li
              key={index + 1}
              className={`page-item ${
                index + 1 === currentPage ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={handleNextPage}
              aria-label="Next"
            >
              <i className="fa fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Productlist;
