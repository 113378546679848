import React, { useState } from "react";
import Footer from "../layout/Footer";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
const accordionItems = [
  {
    title: "Do you offer a free, no obligation quotation?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
  {
    title: "Is there a waiting list for desired work to be started?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
  {
    title: "How are payments handled and dealt with?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
  {
    title: "What is construction management?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
  {
    title: "What are the steps to manage a construction project?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
  {
    title: "What can I expect when working with Graviton Construction?",
    content:
      "Of course. We are more than happy to visit your property at a time convenient for yourself and quote for the required works. Nulla dictum placerat sagittis...",
  },
];
const Faq = () => {
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };
  const [activeIndex, setActiveIndex] = useState(2); // Set the initially active accordion

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Header></Header>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Faq</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Faq</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="faq-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="accordion" id="accordionExample">
                  {accordionItems.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            activeIndex !== index ? "collapsed" : ""
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={activeIndex === index}
                          aria-controls={`collapse${index}`}
                        >
                          {item.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          activeIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body"><p>{item.content}</p></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Partners></Partners>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Faq;
