import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
const StaticPages = () => {
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };
  return (
    <>
      <Header />
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Terms & Condition</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Terms & Condition</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="staticpage">
          <div className="container">
            <h2>Lifting Point 8-231 Metric Thread / DIGITAL</h2>
            <p>
              Simple actions make a difference. It starts and ends with each
              employee striving to work safer every single day so they can
              return.
            </p>
            <ul>
              <li>Pivots through 180°.</li>
              <li>
                Manufactured from forged alloy steel, quenched and tempered.
              </li>
              <li>Manufactured and tested in accordance with EN1677-1.</li>
              <li>Load rated parts are 100% magnaflux crack detected.</li>
              <li>
                Individual forged parts are traceable to Test Certification.
              </li>
              <li>Proof tested to 2.5 times the WLL.</li>
              <li>Fatigue rated to 1.5 times the WLL.</li>
              <li>
                All YOKE Lifting points meet or exceed all the requirements of
                ASME B30.26.
              </li>
              <li>
                WLL forged onto each product for quick and easy identification.
              </li>
              <li>Lugs designed to assist the welding process.</li>
              <li>
                A protected spring keeps the load ring in a required position.
              </li>
            </ul>
            <p>
              Simple actions make a difference. It starts and ends with each
              employee striving to work safer every single day so they can
              return.
            </p>
          </div>
        </section>
        <Partners></Partners>
      </div>
      <Footer />
    </>
  );
};

export default StaticPages;
