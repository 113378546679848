import React from "react";
import Footer from "../layout/Footer";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import Header from "../layout/Header";
import Productlist from "../Component/Productlist";
const Product = () => {
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };

  return (
    <>
      <Header></Header>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Que Solution Products</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Que Solution Products</span>
              </li>
            </ul>
          </div>
        </section>
      <section className="product-list">
        <div className="container">
          <Productlist></Productlist>
        </div>
      </section>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Product;
